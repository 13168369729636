<template>
	<div class="sidenav">
		<ClientOnly>
			<CaHeader
				class="header"
				for-user
				:user-data="userData"
				variant="rounded"
				@user-link-click="openAccount"
			/>
		</ClientOnly>

		<div
			v-if="status === 'pending'"
			class="flex full-height align-items-center justify-content-center"
		>
			<CaLoading />
		</div>

		<div v-else-if="menuItems.length">
			<ClientOnly>
				<WsHeaderMobileMenuCarousel :items="data.carousel" />
			</ClientOnly>
			<WsHeaderMobileMenuItems
				:items="menuItems"
				:departaments="data.menu"
				:account="account"
				@close="account = {}"
			/>
		</div>

		<CaEmptyState
			v-else
			image="https://www.petlove.com.br/static/uploads/banner_image/image/37628/error-state.png"
			title="Alguém andou mexendo nas nossas coisas..."
			btn-label="Tentar novamente"
			@click="refresh"
		>
			Caso o problema continue, tente novamente mais tarde.
		</CaEmptyState>

		<CaIconButton
			class="sidenav__close"
			icon="close"
			aria-label="fechar menu"
			@click="closeSidenav"
		/>
	</div>
</template>

<script setup>
import { useAuthStore } from '@petlove/lion/store/auth';
import { useUsersStore } from '@petlove/lion/store/users';

const emit = defineEmits(['sidebar-close']);
const userStore = useUsersStore();
const authStore = useAuthStore();

const { fetchPointer } = usePointer();

const { data, status, refresh } = await fetchPointer();

const account = reactive({});

const user = computed(() => userStore.getUserPublic);
const isLogged = computed(() => authStore.isLogged);

const userData = computed(() => ({
	name: `Olá, ${user.value?.firstname ?? 'Petlover'}`,
	email: user.value.email,
	link: `${isLogged.value ? 'Sua conta' : 'Entrar'}`,
}));

const menuItems = computed(() => {
	const items = data?.value?.menu?.filter(item => item.type !== 'account');
	return items || [];
});

function openAccount() {
	return isLogged.value ? openAccountPage() : window.open('/entrar/', '_self')
}

function openAccountPage () {
  const accountItem = data.value?.menu?.find(item => item.type === 'account');
  if (accountItem) {
    account.value = { ...accountItem };
  }
}

function closeSidenav() {
	emit('sidebar-close');
}
</script>

<style lang="scss">
.sidenav {
  background-color: color(neutral, lightest);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 3;
  overflow: scroll;

  &__close {
    position: fixed !important;
    bottom: space(6);
    right: space(6);
  }
}
</style>
